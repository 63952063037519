import React from 'react'
import styles from './Loading.module.scss'
import { LOGO } from '../../App'
import translation from 'assets/locales/en/translation.json'

const Loading = () => {
  return (
    <div className={styles.page}>
      <img src={LOGO} className={styles.logo} alt={translation.Loading.img_alt} />
      <h2 className={styles.message}>{translation.Loading.loading}</h2>
    </div>
  )
}

export default Loading
