import { watchCategory } from 'analytics'
import api from 'api'
import classNames from 'classnames'
import useWatchCategory from 'hooks/useWatchCategory'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './CategoriesFilter.module.scss'

type PropsType = {
  categoryId?: number
}

const CategoriesFilter: React.FC<PropsType> = ({ categoryId }) => {
  const navigate = useNavigate()
  const [isMinimize, setIsMinimize] = useState(true)
  const { data: categories } = api.useGetCategoriesQuery()

  const onSelectCategory = (id: number) => {
    if (location.pathname.endsWith(id.toString())) navigate('/products')
    else navigate('/products/' + id)
  }

  useWatchCategory(categoryId)

  return (
    <div className={styles.container}>
      <div className={classNames([styles.categories, { [styles.minimize]: isMinimize }])}>
        {categories?.map((c) => (
          <button
            key={c.id}
            className={classNames([styles.categoryBtn, { [styles.activeCategory]: categoryId === c.id }])}
            onClick={() => onSelectCategory(c.id)}>
            {c.name}
          </button>
        ))}
      </div>
      <button className={styles.allBtn} onClick={() => setIsMinimize((m) => !m)}>
        {isMinimize ? 'All' : 'Close'}
      </button>
    </div>
  )
}

export default CategoriesFilter
