import React, { Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Layout from './components/Layout/Layout'
import Products from './pages/Products/Products'
import Product from 'pages/Product/Product'
import Login from 'pages/Login/Login'
import { useSelector } from './store/store'
import Subscriptions from 'pages/Subscriptions/Subscriptions'
import CampaignProducts from 'pages/CampaignProducts/CampaignProducts'
import Loading from 'pages/Loading/Loading'
import InvestingContainer from 'pages/Investing/InvestingContainer'
import PortfolioConstructor from 'pages/Investing/PortfolioConstructor/PortfolioConstructor'

const Profile = React.lazy(() => import('pages/Profile/Profile'))
const UnsubscribeEmails = React.lazy(() => import('pages/UnsubscribeEmails/UnsubscribeEmails'))
const FromEmail = React.lazy(() => import('./pages/Login/FromEmail'))
const ConfirmEmail = React.lazy(() => import('./pages/Login/ConfirmEmail'))
const Page404 = React.lazy(() => import('./pages/Page404/Page404'))
const ForgotPassword = React.lazy(() => import('./pages/Login/ForgotPassword'))

const AuthRouter = () => (
  <Routes>
    <Route path={'/login'} element={<Login />} />
    <Route path={'/forgot_password'} element={<ForgotPassword />} />
    <Route path={'/from_email'} element={<FromEmail />} />
    <Route path={'/unsubscribe_emails'} element={<UnsubscribeEmails />} />
    <Route path={'*'} element={<Navigate to="/login" />} />
  </Routes>
)

const ConfirmRouter = () => (
  <Routes>
    <Route path={'/confirm'} element={<ConfirmEmail />} />
    <Route path={'*'} element={<Navigate to="/confirm" />} />
  </Routes>
)

const AppRouter = () => {
  const client_id = useSelector((state) => state.profile.client_id)
  return (
    <Routes>
      <Route path="/" element={<Navigate to={'/products'} />} />
      <Route path={'/from_email'} element={<FromEmail />} />
      <Route path={'/unsubscribe_emails'} element={<UnsubscribeEmails />} />
      <Route path="/products" element={<Products />} />
      <Route path="/products/:id" element={<Products />} />
      {client_id && (
        <>
          <Route path="/investing" element={<InvestingContainer />} />
          <Route path="/portfolio_constructor" element={<PortfolioConstructor />} />{' '}
        </>
      )}

      <Route path="/login" element={<Navigate to={'/'} />} />
      <Route path="/forgot_password" element={<Navigate to={'/'} />} />
      <Route path="/product/:id" element={<Product />} />
      <Route path="/campaign_product/:id" element={<CampaignProducts />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/subscriptions" element={<Subscriptions />} />

      <Route path={'*'} element={<Page404 />} />
    </Routes>
  )
}

const Router = () => {
  const isAuth = useSelector((state) => state.profile.isAuth)
  const emailConfirmed = useSelector((state) => state.profile.email_confirmed)
  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        {isAuth ? emailConfirmed ? <AppRouter /> : <ConfirmRouter /> : <AuthRouter />}
      </Suspense>
    </Layout>
  )
}

export default Router
