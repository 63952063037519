import React, { useEffect, useState } from 'react'
import styles from '../Portfolio.module.scss'
import classNames from 'classnames'
import { number2currency } from 'utils'
import SVG from 'components/SVG'
import { useClickOutside } from 'hooks/useClickOutside'
import Modal from 'components/Modal/Modal'
import Input from 'components/Input/Input'
import Button from 'components/Button/Button'
import api from 'api'
import { groupCollapsed } from 'console'

type GoalRowType = {
  goal: Goal
  balance: number
  setIsOpenModal: React.Dispatch<React.SetStateAction<false | 'Add' | 'Edit'>>
  setModalOption: React.Dispatch<
    React.SetStateAction<
      | {
          name: string
          price: number
          id: number
        }
      | undefined
    >
  >
}

const GoalRow: React.FC<GoalRowType> = ({ goal, balance, setIsOpenModal, setModalOption }) => {
  const progress = Math.round((balance / goal.price) * 100)
  const [isShowPopup, setIsShowPopup] = useState(false)
  const ref = useClickOutside(() => setIsShowPopup(false))
  const [deleteGoal, deleteGoalResult] = api.useDeleteGoalMutation()

  return (
    <tr>
      <td>{goal.name}</td>
      <td>{number2currency(goal.price)}</td>
      <td style={{ color: progress < 100 ? 'var(--color-red)' : 'var(--color-green)' }}>{progress}%</td>
      <td style={{ position: 'relative' }}>
        <button className={styles.ghostBtn} onClick={() => setIsShowPopup(true)}>
          <SVG.ThreeDots style={{ width: 20, height: 20 }} />
        </button>
        {isShowPopup && (
          <div ref={ref} className={styles.goalThreeDotsPopup}>
            <button
              onClick={() => {
                setIsShowPopup(false)
                setIsOpenModal('Edit')
                setModalOption({ name: goal.name, price: goal.price, id: goal.id })
              }}>
              Edit
            </button>
            <button
              onClick={() => {
                deleteGoal(goal.id)
                setIsShowPopup(false)
              }}>
              Delete
            </button>
          </div>
        )}
      </td>
    </tr>
  )
}

type Props = {
  balance: number
}

const GoalsBlock = ({ balance }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState<false | 'Add' | 'Edit'>(false)
  const [modalOption, setModalOption] = useState<undefined | { name: string; price: number; id: number }>()
  const goals = api.useGetGoalsQuery()

  return (
    <>
      <div className={classNames([styles.block, styles.goalsBlock])}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
          <p style={{ fontWeight: 'bold' }}>Goals</p>
          <button
            className={styles.ghostBtn}
            onClick={() => {
              setIsOpenModal('Add')
              setModalOption(undefined)
            }}>
            <SVG.Plus style={{ width: 16, height: 16, stroke: 'var(--color-blue)' }} />
          </button>
        </div>
        <table className={styles.goalsTable}>
          <colgroup>
            <col />
            <col />
            <col />
            <col style={{ width: '1%' }} />
          </colgroup>
          <tbody>
            {goals?.data?.map((g, i) => (
              <GoalRow
                setIsOpenModal={setIsOpenModal}
                setModalOption={setModalOption}
                key={g.name + i}
                goal={g}
                balance={balance}
              />
            ))}
          </tbody>
        </table>
      </div>
      {isOpenModal && <GoalsModal modalOption={modalOption} type={isOpenModal} setIsOpenModal={setIsOpenModal} />}
    </>
  )
}

export default GoalsBlock

type GoalsModalType = {
  type: 'Add' | 'Edit'
  setIsOpenModal: React.Dispatch<React.SetStateAction<false | 'Add' | 'Edit'>>
  modalOption:
    | {
        name: string
        price: number
        id: number
      }
    | undefined
}
const GoalsModal: React.FC<GoalsModalType> = ({ setIsOpenModal, type, modalOption }) => {
  const ref = useClickOutside(() => setIsOpenModal(false))
  const [addGoal, addGoalResult] = api.useAddGoalMutation()
  const [editGoal, editGoalResult] = api.useEditGoalMutation()

  const [name, setName] = useState<string | undefined>(modalOption?.name)
  const [nameError, setNameError] = useState<string | undefined>()
  const [price, setPrice] = useState<number | undefined>(modalOption?.price)
  const [priceError, setPriceError] = useState<string | undefined>()

  useEffect(() => {
    if (addGoalResult.isSuccess || editGoalResult.isSuccess) setIsOpenModal(false)
  }, [addGoalResult, editGoalResult])

  console.log('modalOption!!!', modalOption)

  const onSubmit = () => {
    if (!name) return setNameError('Field "Name" cannot be empty')
    else {
      setNameError(undefined)
    }
    if (!price) return setPriceError('Field "Price" cannot be empty')
    else {
      setPriceError(undefined)
    }
    if (type === 'Add') addGoal({ name, price })
    if (type === 'Edit') editGoal({ name, price, id: modalOption?.id! })
  }
  return (
    <Modal>
      <div ref={ref} className={styles.goalsModalContainer}>
        <p style={{ fontWeight: 'bold', marginBottom: 16 }}>
          {type === 'Add' && 'Add new goal'} {type === 'Edit' && 'Edit goal'}
        </p>
        <div className={styles.goalsInputContainer}>
          <p style={{ marginBottom: 4 }}>Name</p>
          <Input
            value={name}
            onChange={(e) => {
              setName(e)
            }}
          />
          {nameError && (
            <p className={styles.error} style={{ marginTop: 4 }}>
              {nameError}
            </p>
          )}
        </div>
        <div className={styles.goalsInputContainer}>
          <p style={{ marginBottom: 4 }}>Price</p>
          <Input
            type={'number'}
            value={price?.toString()}
            onChange={(e) => {
              //@ts-ignore
              setPrice(e)
            }}
          />
          {priceError && (
            <p className={styles.error} style={{ marginTop: 4 }}>
              {priceError}
            </p>
          )}
        </div>

        <Button style={{ marginLeft: 'auto' }} onClick={onSubmit}>
          Send
        </Button>
      </div>
    </Modal>
  )
}
