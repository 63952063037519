import React, { useEffect, useState } from 'react'
import styles from '../../Portfolio.module.scss'

import classNames from 'classnames'
import { number2currency } from 'utils'
import { store, useDispatch, useSelector } from 'store/store'
import AssetsBlockLine from './AssetsBlockLine'
import Button from 'components/Button/Button'
import api from 'api'
import { useNavigate } from 'react-router'
import AssetsChart from './AssetsChart'
import ThreeSelect, { Option } from 'components/ThreeSelect/ThreeSelect'
import PopConfirm from 'components/PopConfirm/PopConfirm'
import { portfolioConstructorActions } from 'store/portfolioConstructorReducer'

const colors = [
  '#2f7ed8',
  '#0d233a',
  '#8bbc21',
  '#910000',
  '#1aadce',
  '#492970',
  '#f28f43',
  '#77a1e5',
  '#c42525',
  '#a6c96a',
]

type AssetsType = {
  equity: number
}

const EditAssetsBlock: React.FC<AssetsType> = ({ equity }) => {
  const locEquity = useSelector((store) => store.portfolioConstructor.equity)
  const curPortfolio = useSelector((store) => store.portfolioConstructor.curPortfolio!)
  const categories = useSelector((store) => store.portfolioConstructor.categories!)
  const assets = useSelector((store) => store.portfolioConstructor.assets)
  const client_id = useSelector((store) => store.profile.client_id)

  console.log(assets)

  const [sendAssets, resultAssets] = api.usePostDemandMutation()

  const [productsError, setProductsError] = useState<string | undefined>()
  const [selectedProducts, setSelectedProducts] = useState<number[]>(
    curPortfolio.assets.map((a) => a.seller_product.id),
  )
  const [isChangeSelectedProduct, setIsChangeSelectedProduct] = useState(false)
  const [isAddProductPopConfirm, setIsAddProductPopConfirm] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (resultAssets.isSuccess) navigate('/investing')
  }, [resultAssets])

  useEffect(() => {
    setSelectedProducts(
      //@ts-ignore
      assets?.map((a) => {
        return a.seller_product.id
      }),
    )
  }, [assets])

  const getIsPercent = (quantity: number, priceName: string, price: number) => {
    if (priceName === 'Min. Investment') return ` (${Number(((quantity / locEquity!) * 100).toFixed(2))}%)`
    else return ` (${Number((((quantity * price) / locEquity!) * 100).toFixed(2))}%)`
  }

  const setProducts = () => {
    setIsChangeSelectedProduct(false)
    const newAssets: any[] = []
    selectedProducts.map((s) => {
      const newAsset = assets?.filter((f) => f.seller_product.id === s)[0]
      //@ts-ignore
      if (newAsset) {
        console.log('newAsset', newAsset)

        newAssets.push(newAsset)
      } else {
        categories.map((c) => {
          c.products.map((p) => {
            if (p.id === s)
              newAssets.push({
                quantity: 0,
                seller_product: {
                  id: p.id,
                  max_investment: p.max_investment,
                  min_investment: p.min_investment,
                  name: p.name,
                  step: p.step,
                  price_name: p.price_name,
                  price: p.price,
                },
              })
          })
        })
      }
    })
    dispatch(portfolioConstructorActions.setAssets(newAssets))
  }

  const threeOptions: Option[] = categories?.map((c) => ({
    id: c.id,
    label: c.name,
    children: c.products.map((p) => ({
      id: p.id,
      label: p.name,
    })),
  }))

  const surcharge = equity - curPortfolio?.equity!
  return (
    <div className={classNames([styles.block, styles.assetsBlock])}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <h3 style={{ marginRight: 6 }}>Balance (Current Portfolio):</h3>
        <p style={{ marginRight: 6 }}>{number2currency(curPortfolio?.equity)}</p>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3 style={{ marginRight: 6 }}>Balance (Configuration Portfolio):</h3>
        <p style={{ marginRight: 6 }}>{number2currency(equity)}</p>
        {surcharge !== 0 && (
          <p style={{ color: surcharge < 0 ? 'var(--color-red)' : 'var(--color-green)' }}>
            {` (${number2currency(surcharge)})`}
          </p>
        )}
      </div>

      <div style={{ minWidth: 300, display: 'flex', width: 300, alignSelf: 'center' }}>
        <AssetsChart
          data={assets?.map((a, i) => {
            return {
              name: a.seller_product.name,
              y: a.seller_product.price_name === 'Min. Investment' ? a.quantity : a.quantity * a.seller_product.price,
              color: colors[i],
            }
          })}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20, position: 'relative' }}>
        <h3 style={{ marginRight: 16 }}>Products:</h3>

        <ThreeSelect
          containerClass={styles.addNewProduct}
          onChange={(e) => {
            setSelectedProducts(e)
            setIsChangeSelectedProduct(true)
          }}
          selected={selectedProducts}
          options={threeOptions}
          error={productsError}
          optionsPosRight
        />

        <Button
          disabled={isAddProductPopConfirm || !isChangeSelectedProduct}
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            setIsAddProductPopConfirm((state) => !state)
          }}>
          Apply
        </Button>
        {isAddProductPopConfirm && (
          <PopConfirm
            className={styles.addProductPopConfirm}
            onClose={() => {
              setIsAddProductPopConfirm(false)
            }}>
            <p style={{ maxWidth: 300, marginBottom: 20 }}>
              Are you sure what you want to change your products? New products that you chose will be added. If you
              remove any products, then they will be removed from the configuration
            </p>
            <div className={styles.addProductPopConfirmBtnContainer}>
              <Button
                onClick={() => {
                  setIsAddProductPopConfirm(false)
                  // setIsDeletePopConfirm(false)
                  // dispatch(portfolioConstructorActions.delAsset(asset.seller_product.id))
                }}
                style={{ backgroundColor: 'var(--color-red)' }}
                className={styles.popConfirmBtn}>
                Cancel
              </Button>
              <Button
                className={styles.popConfirmBtn}
                onClick={() => {
                  setIsAddProductPopConfirm(false)
                  setProducts()
                }}>
                Apply
              </Button>
            </div>
          </PopConfirm>
        )}
      </div>

      <table className={styles.fundsTable}>
        <colgroup>
          <col style={{ width: '10px', paddingRight: '10px' }} />
          <col />
          <col style={{ width: '100%' }} />
          <col style={{ width: '120px' }} />
        </colgroup>
        <tbody>
          {assets?.map((a, i) => (
            <AssetsBlockLine
              key={a?.seller_product.id}
              color={colors[i]}
              asset={a}
              percent={getIsPercent(a?.quantity, a?.seller_product?.price_name, a?.seller_product?.price)}
              isEdit={true}
            />
          ))}
        </tbody>
      </table>
      <Button
        style={{ width: 210, marginLeft: 'auto', marginTop: 20 }}
        onClick={() => {
          sendAssets({
            type: 'change',
            client: client_id!,
            //@ts-ignore
            assets: assets
              ?.map((e) => {
                if (curPortfolio?.assets?.filter((f) => f?.seller_product.id === e?.seller_product.id)?.length! > 0) {
                  if (curPortfolio?.assets.filter((f) => f.quantity === e.quantity)?.length! > 0) {
                    return { id: e.seller_product.id, quantity: e.quantity, is_changed: false }
                  } else {
                    return { id: e.seller_product.id, quantity: e.quantity, is_changed: true }
                  }
                } else {
                  return { id: e.seller_product.id, quantity: e.quantity, is_changed: true }
                }
              })
              .concat(
                //@ts-ignore
                curPortfolio?.assets
                  //@ts-ignore
                  .map((e) => {
                    //@ts-ignore
                    if (assets.filter((f) => f.seller_product.id === e.seller_product.id).length === 0) {
                      return { id: e.seller_product.id, quantity: 0, is_changed: true }
                    }
                  })
                  .filter((f) => f),
              ),
          })
        }}>
        Submit a change request
      </Button>
    </div>
  )
}

export default EditAssetsBlock
