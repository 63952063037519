import SVG from 'components/SVG'
import React from 'react'

type PropsType = {
  title: string
  style?: any
}
const Notifications = {
  Warning: ({ title, ...props }: PropsType) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', ...props.style }}>
        <SVG.Warning style={{ width: 16, fill: '#f7c600' }} />
        <p style={{ marginLeft: 10 }}>{title}</p>
      </div>
    )
  },
}

export default Notifications
