import React, { useEffect, useRef, useState } from 'react'
import styles from './Gallery.module.scss'
import classNames from 'classnames'
import arrow from 'assets/images/arrow.svg'
import fullscreenCloseIcon from 'assets/images/fullscreen_close.svg'
import fullscreenIcon from 'assets/images/fullscreen.svg'

type Props = {
  children: React.ReactNode[]
  controls?: boolean
  showFullscreenBtn?: boolean
  bullets?: boolean
  onSlide?: (slide: number) => void
  onFullscreen?: (isFullscreen: boolean) => void
  className?: string
}

const Gallery = (props: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const childrenRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef(0)
  const [slide, setSlide] = useState(0)
  const [isFullscreen, setIsFullscreen] = useState(false)

  const onSlide = (e: React.MouseEvent<HTMLButtonElement>, side: 1 | -1) => {
    e.stopPropagation()
    if (props.children !== undefined) {
      let nextSlide = slide + side
      if (nextSlide > props.children.length - 1) nextSlide = 0
      else if (nextSlide < 0) nextSlide = props.children.length - 1
      setSlide(nextSlide)
      scroll(nextSlide)
    }
  }

  const scroll = (toSlide: number) => {
    if (containerRef.current !== null && childrenRef.current !== null) {
      scrollRef.current = toSlide * containerRef.current.offsetWidth
      // behavior: 'smooth' doesn't work in safari < 14
      childrenRef.current.scrollTo({ left: scrollRef.current })
    }
  }

  const onFullscreen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setIsFullscreen((f) => !f)
  }

  useEffect(() => {
    props.onSlide?.(slide)
  }, [slide])

  useEffect(() => {
    props.onFullscreen?.(isFullscreen)
    scroll(slide)
  }, [isFullscreen])

  return (
    <div
      ref={containerRef}
      className={classNames([
        styles.container,
        { [styles.fullscreen]: isFullscreen },
        props.className && { [props.className]: !isFullscreen },
      ])}>
      <div ref={childrenRef} className={styles.children}>
        {props.children}
      </div>

      {props.showFullscreenBtn && (
        <button className={styles.fullscreenBtn} onClick={onFullscreen}>
          <img src={isFullscreen ? fullscreenCloseIcon : fullscreenIcon} alt={'Fullscreen'} />
        </button>
      )}

      {props.controls && props.children && props.children.length > 1 && (
        <>
          <button className={styles.arrow} onClick={(e) => onSlide(e, -1)}>
            <img src={arrow} alt={'Previous'} />
          </button>
          <button className={classNames(styles.arrow, styles.rightArrow)} onClick={(e) => onSlide(e, 1)}>
            <img src={arrow} alt={'Next'} />
          </button>
        </>
      )}

      {props.bullets && props.children && props.children.length > 1 && (
        <div className={styles.bullets}>
          {props.children?.map((c, i) => (
            <div key={i} className={classNames([styles.bullet, { [styles.activeBullet]: i === slide }])} />
          ))}
        </div>
      )}
    </div>
  )
}

export default Gallery
