import React, { useEffect } from 'react'
import styles from './Params.module.scss'
import InputParams from './InputParams/InputParams'
import SelectParams from './SelectParams/SelectParams'
import SliderParams from './SliderParams/SliderParams'

type PropsType = {
  params: React.MutableRefObject<Array<SelectType | InputType | SliderType>>
  onParametersFilledChange: (filled: boolean) => void
  capacity: null | number
}

const Params: React.FC<PropsType> = ({ params, onParametersFilledChange, capacity }) => {
  const onChange = () => {
    let isFilled = true
    params.current.forEach((p) => {
      if (p.value === undefined) isFilled = false
    })
    onParametersFilledChange(isFilled)
  }

  useEffect(() => {
    if (params.current.length > 0) onParametersFilledChange(false)
  }, [])

  return (
    <div className={styles.paramsContainer}>
      {params.current.map((e) => {
        switch (e.type) {
          case 'input':
            return (
              <InputParams
                key={e.name + e.type}
                data={e}
                onChange={(value: string | undefined) => {
                  e.value = value
                  onChange()
                }}
              />
            )
          case 'slider':
            if (e.name === 'Investment Amount' && capacity !== null) e.max = Math.min(e.max, capacity)
            return (
              <SliderParams
                key={e.name + e.type}
                data={e}
                onChange={(value: number) => {
                  e.value = value
                  onChange()
                }}
              />
            )
          case 'select':
            return (
              <SelectParams
                key={e.name + e.type}
                data={e}
                onChange={(value: number | string) => {
                  e.value = value
                  onChange()
                }}
              />
            )
        }
      })}
    </div>
  )
}

export default Params
