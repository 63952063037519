import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { profileActions } from 'store/profileReducer'
import { RootState, store } from './store/store'
import md5 from 'md5'

// const API_URL = __DEV__ ? 'https://stage2.splinex.com/api' : 'https://wealthback.cecure.io/api'
const API_URL = 'https://wealthback.cecure.io/api'
const PASSWORD_SALT = '4048b685204f7638ec07e56d0f7115e8'

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).profile.token
    // if (token) headers.set('Authorization', 'b32a51ac9c1a1706cc21f0f01e881f73')
    if (token) headers.set('authorization', token)
    return headers
  },
})

const api = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: ['Products', 'Product', 'Likes', 'SellerFirms', 'Demand', 'Portfolio', 'Goals'],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body: { username: string; password: string }) => {
        return {
          url: 'login',
          method: 'POST',
          body: {
            ...body,
            password: md5(body.password + PASSWORD_SALT),
            email: body.username,
          },
        }
      },
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((r) => {
            if (r.data.error === undefined) {
              dispatch(profileActions.setUser(r.data))
            }
          })
          .catch((e) => {
            console.log('LOGIN ERROR', e)
          })
      },
    }),
    getUserInfo: builder.query<Omit<User, 'token'>, void>({
      query: () => '/user',
      async onQueryStarted(arg: void, { queryFulfilled, dispatch }) {
        queryFulfilled.then((r) => {
          dispatch(profileActions.updateUserInfo(r.data))
          dispatch(api.endpoints.getShortSubscribes.initiate({ user: r.data.id }))
        })
      },
    }),
    patchUserInfo: builder.mutation<Omit<User, 'token'>, UserPatchBody>({
      query: (body) => {
        return {
          url: '/user',
          method: 'PATCH',
          body,
        }
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled.then((r) => {
          dispatch(profileActions.updateUserInfo(r.data))
        })
      },
    }),
    setUserAvatar: builder.mutation<{ url: string }, FormData>({
      query: (body) => {
        return {
          url: '/user/avatar',
          method: 'POST',
          body,
        }
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled.then((r) => {
          dispatch(profileActions.updateUserInfo({ avatar_url: r.data.url }))
        })
      },
    }),
    getUserById: builder.query<Omit<User, 'token'>, number>({
      query: (id) => 'user_by_id/' + id,
    }),
    changePassword: builder.mutation<User, { password: string }>({
      query: ({ password }) => ({
        url: '/change_password',
        method: 'PATCH',
        body: {
          password: md5(password + PASSWORD_SALT),
        },
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((r) => {
            dispatch(profileActions.setUser(r.data))
          })
          .catch((e) => {
            console.log('LOGIN ERROR', e)
          })
      },
    }),
    resetPassword: builder.mutation<unknown, { email: string }>({
      query: (body) => ({ url: '/reset_password', method: 'POST', body }),
    }),
    getTokenForCode: builder.mutation<User | { error: string }, { email: string; code: string }>({
      query: (body) => ({ url: '/reset_password_code_confirmation', method: 'POST', body }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((r) => {
            if (!('error' in r.data)) {
              dispatch(profileActions.updateUserInfo({ token: r.data.token }))
            }
          })
          .catch((e) => {
            console.log('LOGIN ERROR', e)
          })
      },
    }),
    getUserAdvisers: builder.query<Omit<User, 'token'>[], void>({
      query: () => {
        const myId = store.getState().profile.id as number
        return {
          url: '/advisors_by_user/' + myId,
        }
      },
    }),
    getImportedClient: builder.query<ImportedClient, { seller_firm: number; user: number }>({
      query: (params) => {
        return {
          url: '/imported_client',
          params,
        }
      },
      async onQueryStarted(arg: { seller_firm: number; user: number }, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then((r) => {
            dispatch(profileActions.updateUserInfo({ client_id: r.data.id }))
          })
          .catch((err) => {
            dispatch(profileActions.updateUserInfo({ client_id: undefined }))
          })
      },
    }),

    getCategories: builder.query<Category[], void>({
      query: () => '/categories',
    }),

    //Products
    getProducts: builder.query<Product[], ProductRequestParams | void>({
      query: (params) => {
        return {
          url: '/issuer_products',
          method: 'GET',
          params: { with_sellers: 1, ...params },
        }
      },
      providesTags: (result, error, arg) => [
        { id: arg?.toString(), type: 'Products' },
        { id: 'all', type: 'Products' },
      ],
    }),
    getSellerProducts: builder.query<Product[], ProductRequestParams | void>({
      query: (params) => {
        return {
          url: '/seller_products',
          method: 'GET',
          params: { ...params },
        }
      },
    }),
    getProduct: builder.query<Product, number>({
      query: (id) => '/issuer_product/' + id,
      providesTags: (result, error, id) => [
        { id: id.toString(), type: 'Product' },
        { id: 'all', type: 'Product' },
      ],
    }),
    getProductSellers: builder.query<ProductSeller[], number>({
      query: (id) => '/issuer_product/' + id + '/sellers',
    }),

    //Likes
    getLikes: builder.query<number[], LikesRequestParams | void>({
      query: (args) => {
        const params: any = { ...args }
        if (params !== undefined && 'products' in params) params.products = params.products?.join(',')
        return {
          url: '/likes',
          method: 'GET',
          params,
        }
      },
      providesTags: [{ id: 'all', type: 'Likes' }],
    }),
    postLike: builder.mutation<{ Success: boolean }, number>({
      query: (product) => {
        return {
          url: '/likes',
          method: 'POST',
          body: {
            product,
          },
        }
      },
      onQueryStarted(productId, { dispatch, queryFulfilled, getState }) {
        queryFulfilled.then((resp) => {
          if (resp.data.Success) {
            // upd likes
            for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
              { type: 'Likes', id: 'all' },
            ])) {
              if (endpointName !== 'getLikes') continue
              dispatch(
                api.util.updateQueryData(endpointName, originalArgs, (likes) => {
                  likes.push(productId)
                }),
              )
            }
            // upd products like count
            for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
              { type: 'Products', id: 'all' },
            ])) {
              if (endpointName !== 'getProducts') continue
              dispatch(
                api.util.updateQueryData(endpointName, originalArgs, (products) => {
                  const product = products.find((p) => (p.issuer_firm_product_id ?? p.id) === productId)
                  if (product !== undefined) Object.assign(product, { like_count: product.like_count + 1 })
                }),
              )
            }
            // upd product like count
            for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
              { type: 'Product', id: 'all' },
            ])) {
              if (endpointName !== 'getProduct') continue
              dispatch(
                api.util.updateQueryData(endpointName, originalArgs, (product) => {
                  if ((product.issuer_firm_product_id ?? product.id) === productId) {
                    Object.assign(product, { like_count: product.like_count + 1 })
                  }
                }),
              )
            }
          }
        })
      },
    }),
    deleteLike: builder.mutation<{ Success: boolean }, number>({
      query: (product) => {
        return {
          url: '/likes',
          method: 'delete',
          params: {
            product,
          },
        }
      },
      onQueryStarted(productId, { dispatch, queryFulfilled, getState }) {
        queryFulfilled.then((resp) => {
          if (resp.data.Success) {
            // upd likes
            for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
              { type: 'Likes', id: 'all' },
            ])) {
              if (endpointName !== 'getLikes') continue
              dispatch(
                api.util.updateQueryData(endpointName, originalArgs, (likes) => {
                  const index = likes.findIndex((l) => l === productId)
                  if (index !== -1) likes.splice(index, 1)
                }),
              )
            }
            // upd products like count
            for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
              { type: 'Products', id: 'all' },
            ])) {
              if (endpointName !== 'getProducts') continue
              dispatch(
                api.util.updateQueryData(endpointName, originalArgs, (products) => {
                  const product = products.find((p) => (p.issuer_firm_product_id ?? p.id) === productId)
                  if (product !== undefined) Object.assign(product, { like_count: product.like_count - 1 })
                }),
              )
            }
            // upd product like count
            for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
              { type: 'Product', id: 'all' },
            ])) {
              if (endpointName !== 'getProduct') continue
              dispatch(
                api.util.updateQueryData(endpointName, originalArgs, (product) => {
                  if ((product.issuer_firm_product_id ?? product.id) === productId) {
                    Object.assign(product, { like_count: product.like_count - 1 })
                  }
                }),
              )
            }
          }
        })
      },
    }),

    //Subscribes
    getShortSubscribes: builder.query<ShortSubscribe[], SubscribeRequestParams>({
      query: (params) => {
        return {
          url: '/subscribes',
          method: 'GET',
          params: { ...params, preview: 1, is_subscribed: 1 },
        }
      },
    }),
    getSubscribes: builder.query<Subscribe[], SubscribeRequestParams>({
      query: (params) => {
        return {
          url: '/subscribes',
          method: 'GET',
          params: {
            ...params,
            is_subscribed: 1,
          },
        }
      },
    }),
    setSubscribe: builder.mutation<Subscribe, SubscribePostRequestParams>({
      query: (body) => {
        return {
          url: '/subscribe',
          method: 'POST',
          body,
        }
      },
      onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        queryFulfilled.then((resp) => {
          const subscribe = resp.data
          const user = (getState() as RootState).profile.id!
          dispatch(
            api.util.updateQueryData('getSubscribes', { user }, (subscribes) => {
              subscribes.push(subscribe)
            }),
          )
          dispatch(
            api.util.updateQueryData('getShortSubscribes', { user }, (subscribes) => {
              subscribes.push({ id: subscribe.id, product: subscribe.product.id })
            }),
          )
        })
      },
    }),
    deleteSubscribe: builder.mutation<void, number>({
      query(id) {
        return {
          url: `/subscribe/${id}`,
          method: 'PATCH',
          body: {
            is_subscribed: false,
          },
        }
      },
      onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        queryFulfilled.then(() => {
          const user = (getState() as RootState).profile.id!
          dispatch(
            api.util.updateQueryData('getSubscribes', { user }, (subscribes) => {
              return subscribes.filter((s) => s.id !== id)
            }),
          )
          dispatch(
            api.util.updateQueryData('getShortSubscribes', { user }, (subscribes) => {
              return subscribes.filter((s) => s.id !== id)
            }),
          )
        })
      },
    }),

    unsubscribeEmail: builder.query<any, { client_id: string; ch: string }>({
      query: (params) => {
        return {
          url: `/imported_client/unsubscribe/${params.client_id}`,
          method: 'GET',
          params: {
            h: params.ch,
          },
        }
      },
    }),
    unsubscribeTillDateEmail: builder.query<any, { client_id: string; ch: string; t: number }>({
      query: (params) => {
        return {
          url: `/imported_client/unsubscribe_till_date/${params.client_id}`,
          method: 'GET',
          params: {
            h: params.ch,
            t: params.t,
          },
        }
      },
    }),
    getCategoriesUser: builder.query<(Category & { active: boolean })[], { user_id: string; uh: string }>({
      query: (params) => {
        return {
          url: `/user_categories/${params.user_id}`,
          method: 'GET',
          params: {
            h: params.uh,
          },
        }
      },
    }),
    setCategoriesUser: builder.mutation<any, { user_id: string; uh: string; data: { [key: string]: boolean } }>({
      query: (body) => {
        return {
          url: `/user_categories/${body.user_id}`,
          method: 'POST',
          params: {
            h: body.uh,
          },
          body: body.data,
        }
      },
    }),

    // Seller firms
    getSellerFirms: builder.query<Firm[], number[] | void>({
      query: (args) => {
        const seller_firms = args?.join(',')
        return {
          url: '/seller_firms',
          method: 'GET',
          params: {
            seller_firms,
          },
        }
      },
      providesTags: [{ id: 'all', type: 'SellerFirms' }],
    }),

    // Investing category
    getInvestingCategories: builder.query<InvestingCategory[], InvestingCategoryRequestParams>({
      query: (params) => {
        return {
          url: '/category_investing',
          method: 'GET',
          params,
        }
      },
    }),

    // Portfilio
    getPortfolio: builder.query<Portfolio, { client: number }>({
      query: (params) => {
        return {
          url: '/portfolio',
          method: 'GET',
          params,
        }
      },
      providesTags: [{ id: 'all', type: 'Portfolio' }],
    }),

    portfolioConfiguration: builder.mutation<PortfolioConfiguration, PortfolioConfigurationRequestType>({
      query: (body) => {
        return {
          url: `/portfolio/configuration`,
          method: 'POST',
          params: { seller_firm: body.seller_firm },
          body,
        }
      },
    }),

    // Investing portfilio constructor
    // getInvestingChart: builder.query<any, void>({
    //   query: (args) => {
    //     return {
    //       url: 'https://wealth.splinex.com/api/goal/51',
    //       method: 'GET',
    //     }
    //   },
    // }),

    // Investing demand
    getDemand: builder.query<DemandResponse[], { client?: number; is_archived?: 0 | 1 } & DemandStatusName>({
      query: (params) => {
        return {
          url: '/demand',
          method: 'GET',
          params,
        }
      },
      providesTags: [{ id: 'all', type: 'Demand' }],
    }),
    postDemand: builder.mutation<any, PostDemandBody>({
      query: (body) => {
        return {
          url: '/demand',
          method: 'POST',
          body: body,
        }
      },
      invalidatesTags: ['Demand'],
    }),

    // Goal
    getGoals: builder.query<Goal[], void>({
      query: () => {
        return {
          url: '/goals',
          method: 'GET',
        }
      },
      providesTags: [{ id: 'all', type: 'Goals' }],
    }),
    addGoal: builder.mutation<Goal, Omit<Goal, 'id'>>({
      query: (body) => {
        return {
          url: '/goal',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ id: 'all', type: 'Goals' }],
    }),
    editGoal: builder.mutation<Goal, Goal>({
      query: (body) => {
        return {
          url: '/goal/' + body.id,
          method: 'PATCH',
          body: {
            name: body.name,
            price: body.price,
          },
        }
      },
      invalidatesTags: [{ id: 'all', type: 'Goals' }],
    }),
    deleteGoal: builder.mutation<any, number>({
      query: (id) => {
        return {
          url: '/goal/' + id,
          method: 'DELETE',
        }
      },
      invalidatesTags: [{ id: 'all', type: 'Goals' }],
    }),
    getNewChart: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: '/portfolio/custom_configuration',
          method: 'POST',
          body,
        }
      },
    }),
  }),
})

export default api
