import React from 'react'
import api from 'api'
import ProductCard from 'components/ProductCard/ProductCard'
import styles from './CampaignProducts.module.scss'
import Loading from '../Loading/Loading'
import { useParams } from 'react-router'
import Page404 from '../Page404/Page404'
import translation from 'assets/locales/en/translation.json'

const CampaignProducts = () => {
  const { id } = useParams()
  const { data, isLoading, isError } = api.useGetSellerProductsQuery({ campaign: Number(id) || undefined })

  if (isLoading) return <Loading />
  else if (isError) return <Page404 />
  else {
    return (
      <div className={styles.container}>
        <h1 className={styles.title}>{translation.CampaignProducts.title}</h1>
        <div className={styles.productsContainer}>
          {data?.map((e) => (
            <ProductCard key={e.id} data={e} />
          ))}
        </div>
      </div>
    )
  }
}

export default CampaignProducts
