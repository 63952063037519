import Input from 'components/Input/Input'
import SVG from 'components/SVG'
import React, { useEffect, useState } from 'react'
import styles from './AssetsBlockLine.module.scss'
import { getNewChart, portfolioConstructorActions } from 'store/portfolioConstructorReducer'
import { useDispatch, useSelector } from 'store/store'
import { number2currency } from 'utils'
import SliderPortfolio from '../SliderPortfolio'
import PopConfirm from 'components/PopConfirm/PopConfirm'
import Button from 'components/Button/Button'
type PropsType = {
  asset: AssetPortfolio
  percent: string
  color: string
  isEdit?: boolean
}
const AssetsBlockLine: React.FC<PropsType> = ({ asset, percent, color, isEdit }) => {
  const [isDeletePopConfirm, setIsDeletePopConfirm] = useState(false)
  const [anotherAsset, setAnotherAsset] = useState<undefined | AssetPortfolio>()
  const assets = useSelector((store) => store.portfolioConstructor.assets)
  const anotherAssets = useSelector((store) => store.portfolioConstructor.anotherAssets)
  const dispatch = useDispatch()
  const onChange = (newValue: number) => {
    dispatch(portfolioConstructorActions.setAsset({ ...asset, quantity: Number(newValue) }))
    getNewChart('my_chart')
    // Number(newValue) Нужно потому-что если плохо кликнуть, то слайдер вернет массив с цифрой :(
  }

  useEffect(() => {
    if (anotherAssets) {
      const newAnotherAsset = anotherAssets.find((f) => f.seller_product.id === asset.seller_product.id)
      if (newAnotherAsset) {
        if (newAnotherAsset.quantity !== asset.quantity) setAnotherAsset(newAnotherAsset)
        else setAnotherAsset(undefined)
      }
    }
  }, [anotherAssets, assets])

  console.log('anotherAsset', anotherAsset)

  return (
    <tr>
      <td style={{ background: color }}></td>
      <td style={{ paddingLeft: 12 }}>{asset?.seller_product?.name}</td>
      {isEdit && (
        <td style={{ textAlign: 'center' }}>
          {asset.seller_product.price_name === 'Min. Investment' ? (
            <SliderPortfolio
              max={asset?.seller_product?.max_investment ?? 0}
              min={asset?.seller_product?.min_investment ?? 0}
              step={asset?.seller_product?.step ?? 0}
              defaultValue={asset?.quantity}
              onChange={onChange}
              anotherAsset={anotherAsset}
            />
          ) : (
            <Input
              containerClass={styles.inputContainer}
              value={asset.quantity.toFixed(0)}
              onChange={(e) => onChange(Number(e))}
              type={'number'}
            />
          )}
        </td>
      )}
      <td style={{ textAlign: 'end' }}>
        {number2currency(
          asset.seller_product.price_name === 'Min. Investment'
            ? asset?.quantity
            : asset?.quantity * asset.seller_product.price,
        ) + percent}
      </td>
      {isEdit && (
        <td style={{ textAlign: 'end', width: 16, position: 'relative' }}>
          <button
            style={{ border: 0, background: 0, cursor: 'pointer' }}
            onClick={() => setIsDeletePopConfirm(!isDeletePopConfirm)}>
            <SVG.Trash style={{ fill: 'var(--color-red)' }} />
          </button>
          {isDeletePopConfirm && (
            <PopConfirm className={styles.popConfirmContainer} onClose={() => setIsDeletePopConfirm((state) => !state)}>
              <p style={{ maxWidth: 210, marginBottom: 20, textAlign: 'left' }}>
                You are sure what you want to delete <b>{asset.seller_product.name}</b>?
              </p>
              <div className={styles.popConfirmBtnContainer}>
                <Button className={styles.popConfirmBtn} onClick={() => setIsDeletePopConfirm(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setIsDeletePopConfirm(false)
                    dispatch(portfolioConstructorActions.delAsset(asset.seller_product.id))
                    getNewChart('my_chart')
                  }}
                  style={{ backgroundColor: 'var(--color-red)' }}
                  className={styles.popConfirmBtn}>
                  Delete
                </Button>
              </div>
            </PopConfirm>
          )}
        </td>
      )}
    </tr>
  )
}

export default AssetsBlockLine
