import React, { useEffect, useState } from 'react'
import styles from './PortfolioConstructor.module.scss'
import api from 'api'
import Loading from 'pages/Loading/Loading'
import ParamsBlock from './Components/ParamsBlock'
import MainBlock from '../Components/MainBlock'
import AssetsBlock from '../Components/Assets/AssetsBlock'
import { useSelector } from 'store/store'
import { useDispatch } from 'react-redux'
import { portfolioConstructorActions } from 'store/portfolioConstructorReducer'
import EditAssetsBlock from '../Components/Assets/EditAssetsBlock'
import { PortfolioWS } from 'websocket/WebSocket'

const PortfolioConstructor = ({ assets, equity }: { assets?: AssetPortfolio[]; equity: number }) => {
  const initialAmmount = 3015450
  const balance = 4220465

  return (
    <div className={styles.page}>
      <MainBlock balance={balance} initialAmmount={initialAmmount} isEdit />
      <ParamsBlock />
      <EditAssetsBlock equity={equity} />
    </div>
  )
}

const PortfolioConstructorContainer = () => {
  const dispatch = useDispatch()
  const client_id = useSelector((state) => state.profile.client_id)
  const categories = useSelector((state) => state.portfolioConstructor.categories!)
  const portfolio = useSelector((state) => state.portfolioConstructor.curPortfolio!)
  const assets = useSelector((state) => state.portfolioConstructor.assets!)
  const equity = useSelector((state) => state.portfolioConstructor.equity!)

  useEffect(() => {
    if (assets) {
      let x = 0
      const equity = Math.round(
        assets
          .map(
            (a) =>
              (x +=
                a.seller_product.price_name === 'Min. Investment' ? a.quantity : a.quantity * a.seller_product.price),
            (x = 0),
          )
          .reverse()[0],
      )
      dispatch(portfolioConstructorActions.setEquity(equity))
    }
  }, [assets])

  const [wsReady, setWsReady] = useState(false)
  useEffect(() => {
    PortfolioWS.initSocket(() => setWsReady(true))
    if (wsReady) {
      // GameService.
    }
  }, [wsReady])

  const {
    data: portfolioResp,
    isError: prtfError,
    isSuccess: prtfOk,
  } = api.useGetPortfolioQuery({ client: client_id! })
  const {
    data: categoriesResp,
    isError: ctgError,
    isSuccess: ctgOk,
  } = api.useGetInvestingCategoriesQuery({
    seller_firm: Number(process.env.REACT_APP_FIRM),
  })

  useEffect(() => {
    if (prtfOk && portfolioResp !== undefined) {
      dispatch(portfolioConstructorActions.setCurPortfolio(portfolioResp))
      dispatch(portfolioConstructorActions.setAssets(portfolioResp.assets))
      dispatch(portfolioConstructorActions.setRiskLevel(5))
      dispatch(portfolioConstructorActions.setDailyDrawdown(15))
      dispatch(portfolioConstructorActions.setSharpe(2.5))
      dispatch(
        portfolioConstructorActions.setMyChart({
          chart: portfolioResp.chart,
          down_bound: portfolioResp.down_bound,
          real_chart: portfolioResp.real_chart,
          times: portfolioResp.times,
          up_bound: portfolioResp.up_bound,
        }),
      )
    }
  }, [prtfOk])

  useEffect(() => {
    if (ctgOk && categoriesResp !== undefined) dispatch(portfolioConstructorActions.setCategories(categoriesResp))
  }, [ctgOk])

  if (prtfError || ctgError) return <h2>Error</h2>
  if (portfolio === undefined || categories === undefined || portfolioResp === undefined) return <Loading />
  return <PortfolioConstructor assets={assets} equity={equity} />
}

export default PortfolioConstructorContainer
