import { store } from 'store/store'

const analyticsFetch = async (url: string, params: IWatch, method?: 'POST' | 'PATCH') => {
  const user = store.getState().profile.id
  const body = { ...params, duration: params.duration / 1000, user }

  await fetch('https://wealthback.cecure.io/analytic/' + url, {
    method: method ?? 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

interface IWatch {
  duration: number
}

export interface WatchIssuerProductI extends IWatch {
  issuer_product: number
}
export const watchIssuerProduct = async (props: WatchIssuerProductI) => {
  await analyticsFetch('watch_issuer_product', props)
}
export interface WatchProductI extends IWatch {
  id: number
}
export const watchProduct = async (props: WatchProductI) => {
  await analyticsFetch('product_watch', props, 'PATCH')
}

interface WatchIssuerFirmI extends IWatch {
  issuer_firm: number
}
export const watchIssuerFirm = async (props: WatchIssuerFirmI) => {
  await analyticsFetch('watch_issuer_firm', props)
}

interface WatchCategoryI extends IWatch {
  category: number
}
export const watchCategory = async (props: WatchCategoryI) => {
  await analyticsFetch('watch_category', props)
}

type WatchVideo = IWatch & {
  video: number
}
export const watchVideo = async (props: WatchVideo) => {
  await analyticsFetch('watch_video', props)
}
