import React from 'react'
import styles from './Modal.module.scss'
import { useClickOutside } from '../../hooks/useClickOutside'

type Props = {
  onClose?: () => void
}

const Modal: React.FC<Props> = ({ children, onClose }) => {
  const ref = useClickOutside(onClose)

  return (
    <div className={styles.wrapper}>
      <div className={styles.content} ref={ref}>
        {children}
      </div>
    </div>
  )
}

export default Modal
