import React from 'react'
import ScatterChart from './ScatterChart'
import PieChart from './PieChart'
import BarChart from './BarChart'
import { Chart, CHART_AXIS, CHART_TYPE } from 'types/chartTypes'

export const bottomTooltipFormat = {
  xDateFormat: '%d.%m.%Y %H:%M:%S',
}

const getChartByChartType = (
  t: CHART_TYPE,
): React.FC<{
  title: string
  data: [string | number, number | null][]
  x_label: string
  y_label: string
  y_type: CHART_AXIS
  x_type: CHART_AXIS
}> => {
  switch (t) {
    case CHART_TYPE.bar:
      return BarChart
    case CHART_TYPE.pie:
      return PieChart
    case CHART_TYPE.scatter:
      return ScatterChart
  }

  // eslint-disable-next-line react/display-name
  return () => <p>Chart not implemented</p>
}

const ProductChart: React.FC<{ data: Chart }> = ({ data }) => {
  const ChartOfType = getChartByChartType(data.chart_type)

  return (
    <div>
      <p>
        <b>{data.title}</b>
      </p>
      <div>
        <ChartOfType
          title={data.title}
          y_label={data.y_label}
          x_label={data.x_label}
          y_type={data.y_type}
          x_type={data.x_type}
          //@ts-ignore
          data={data.chart.x.map((x, i) => [x, data.chart.y[i]])}
        />
      </div>
    </div>
  )
}

export default ProductChart
