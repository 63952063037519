import React, { useState } from 'react'
import styles from './InputParams.module.scss'

type PropsType = {
  onChange: (value: string | undefined) => void
  data: InputType
}

const InputParams: React.FC<PropsType> = ({ data, ...props }) => {
  const [value, setValue] = useState<string>()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value !== '' ? e.target.value : undefined
    setValue(value)
    props.onChange(value)
  }

  return (
    <div className={styles.inputContainer}>
      <p className={styles.title}>{data.name}:</p>
      <input value={value} onChange={onChange} className={styles.input} />
    </div>
  )
}

export default InputParams
