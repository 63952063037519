import React from 'react'
import styles from './Button.module.scss'
import classNames from 'classnames'

type PropsType = React.ButtonHTMLAttributes<any> & {
  className?: string
  secondary?: boolean
  onClick?: (e: React.MouseEvent) => void
}

const Button: React.FC<PropsType> = ({ children, className, onClick, secondary, ...props }) => {
  return (
    <button
      {...props}
      onClick={onClick}
      className={classNames(styles.btn, className, { [styles.secondary]: secondary })}>
      {children}
    </button>
  )
}

export default Button
