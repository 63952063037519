import React from 'react'
import styles from './Footer.module.scss'
import sslSecure from 'assets/images/ssl-secure.png'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <img src={sslSecure} className={styles.sslSecureImg} alt="ssl-secure" />
      </div>
    </footer>
  )
}

export default Footer
