import React from 'react'
import styles from './GoToApp.module.scss'
import { isIOS } from 'utils'
import { LOGO } from '../../App'

const GoToApp = () => {
  const isIos = isIOS()
  const link = window.location.href.replace('https', process.env.REACT_APP_THEME!).replace('/from_email', '')

  return isIos ? (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img className={styles.logo} src={LOGO} alt="" />
        <div>
          <p>{process.env.REACT_APP_NAME} App</p>
        </div>
      </div>

      <a className={styles.btn} href={link}>
        Open
      </a>
    </div>
  ) : null
}

export default GoToApp
