import React, { useEffect } from 'react'
import styles from './Portfolio.module.scss'
import AssetsBlock from './Components/Assets/AssetsBlock'
import GoalsBlock from './Components/GoalsBlock'
import MainBlock from './Components/MainBlock'
import api from 'api'
import { useDispatch, useSelector } from 'store/store'
import { portfolioConstructorActions } from 'store/portfolioConstructorReducer'
const Portfolio: React.FC = () => {
  const initialAmmount = 3015450
  const balance = 4220465

  const dispatch = useDispatch()
  const client_id = useSelector((state) => state.profile.client_id)
  const portfolio = useSelector((state) => state.portfolioConstructor.curPortfolio!)
  const {
    data: categoriesResp,
    isError: ctgError,
    isSuccess: ctgOk,
  } = api.useGetInvestingCategoriesQuery({
    seller_firm: Number(process.env.REACT_APP_FIRM),
  })

  const {
    data: portfolioResp,
    isError: prtfError,
    isSuccess: prtfOk,
  } = api.useGetPortfolioQuery({ client: client_id! })

  useEffect(() => {
    if (prtfOk && portfolioResp !== undefined) {
      dispatch(portfolioConstructorActions.setCurPortfolio(portfolioResp))
    }
  }, [prtfOk])

  useEffect(() => {
    if (ctgOk && categoriesResp !== undefined) dispatch(portfolioConstructorActions.setCategories(categoriesResp))
  }, [ctgOk])
  return (
    <div className={styles.page}>
      <MainBlock balance={balance} initialAmmount={initialAmmount} />
      <GoalsBlock balance={balance} />
      {/* @ts-ignore */}
      <AssetsBlock assets={portfolio?.assets} equity={portfolio?.equity} />
    </div>
  )
}

export default Portfolio
