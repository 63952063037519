import React, { useRef, useState } from 'react'
import QRCode from 'react-qr-code'
import Modal from 'components/Modal/Modal'
import styles from './SharePortfolioModal.module.scss'
import SVG from 'components/SVG'
import Button from 'components/Button/Button'
import { useClickOutside } from 'hooks/useClickOutside'

type PropsType = {
  link: string
  onClose: (value: false) => void
}

const SharePortfolioModal: React.FC<PropsType> = ({ link, onClose }) => {
  const [offerCopy, setOfferCopy] = useState(true)
  const ref = useClickOutside(() => onClose(false))
  const linkRef = useRef<HTMLInputElement>(null)

  const onClick = () => {
    if (!linkRef.current) return
    linkRef.current.select()
    linkRef.current.setSelectionRange(0, 99999)
    document.execCommand('copy')
    setOfferCopy(false)
    setTimeout(() => {
      setOfferCopy(true)
    }, 2000)
  }
  return (
    <Modal>
      <div className={styles.container} ref={ref}>
        <div className={styles.titleContainer}>
          <p>Share your portfolio</p>
          <button className={styles.ghostBtn} style={{ marginLeft: 'auto' }} onClick={() => onClose(false)}>
            <SVG.Cross style={{ width: 16, height: 16, stroke: 'var(--color-text)', strokeWidth: 3 }} />
          </button>
        </div>
        <div className={styles.content}>
          <QRCode value={link} size={256} />
          <div className={styles.linkContainer}>
            {/* <p>{link}</p> */}
            <input ref={linkRef} className={styles.linkInput} readOnly value={link} />
            <Button onClick={onClick} style={{ marginLeft: 'auto' }}>
              {offerCopy ? 'Copy' : 'Copied!'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SharePortfolioModal
