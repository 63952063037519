import React from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { CHART_AXIS } from 'types/chartTypes'

type Props = {
  title: string
  data: [string | number, number | null][]
  x_label: string
  y_label: string
  y_type: CHART_AXIS
  x_type: CHART_AXIS
  options?: Highcharts.Options
}

const PieChart: React.FC<Props> = (props) => {
  const options: Highcharts.Options = {
    chart: { type: 'pie' },
    title: { text: props.title },
    series: [
      {
        name: 'Series',
        type: 'pie',
        colorByPoint: true,
        data: props.data.map((p) => ({ name: p[0], y: p[1] })),
      },
    ],
    ...props.options,
  }

  return (
    <HighchartsReact highcharts={Highcharts} options={options} constructorType={'chart'} style={{ width: '100%' }} />
  )
}

export default PieChart
