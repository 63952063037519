import { io, Socket } from 'socket.io-client'

// type CreateGameProps = {
//   myId: string
//   mode?: PoolType
//   create?: boolean
//   roomId?: string
//   onlyMicro?: boolean
//   listeners?: any
// }

// type JoinProps = {
//   user_name: string
//   pool_type: PoolType | undefined
//   room_name: string | undefined
//   create?: boolean
//   ya_token?: string
//   avatar?: string
//   only_micro?: boolean
// }

const SOCKET_URL = 'https://stage2.splinex.com'
// const SOCKET_URL = '192.168.5.174:5001'

export class PortfolioWS {
  private static socketReady = false
  private static socket: Socket | null = null

  static initSocket = (cb: () => void) => {
    if (PortfolioWS.socket !== null) return

    PortfolioWS.socket = io(SOCKET_URL)
    PortfolioWS.socket.on('connect', () => {
      PortfolioWS.socketReady = true
      console.log('%cSOCKET CONNECTED')
      PortfolioWS.socket?.emit('hello', {
        user: 'user7',
      })
      PortfolioWS.socket?.on('hello', (msg: { room: string }) => {
        console.log('hello', msg)
        cb()
      })
    })
  }

  static emit = (massedge: any) => {
    console.log('start game emit')
    PortfolioWS.socket?.emit('update', massedge)
  }
  static emitMessage = () => {
    console.log('start game emit')
    PortfolioWS.socket?.emit('message', { to: 'user2', data: { message: 'Привет Константин!' } })
  }
}
