import React, { useEffect, useState } from 'react'
import Slider from 'rc-slider'
import styles from './SliderPortfolio.module.scss'
import PortfolioConfirmationForm from './PortfolioConfirmationForm/PortfolioConfirmationForm'
import { useDispatch } from 'store/store'
import { portfolioConstructorActions } from 'store/portfolioConstructorReducer'

type PropsType = {
  max: number
  min: number
  step: number
  defaultValue: number
  onChange: (value: number) => void
  anotherAsset?: AnotherAssetPortfolio
}

const SliderPortfolio: React.FC<PropsType> = ({ max, min, step, defaultValue, onChange, anotherAsset }) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState<number>(defaultValue)
  const test = { width: 40, height: 40 }
  anotherAsset?.quantity
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])
  // const marks = { 70000: { label: '70000', style: { top: -36 } } }
  const mark = {}
  if (anotherAsset?.quantity && !anotherAsset.is_change)
    //@ts-ignore
    mark[anotherAsset?.quantity] = { label: anotherAsset?.quantity, style: { top: -36 } }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <p style={{ width: 80, textAlign: 'left' }}>{value.toFixed(0)}</p>
      <div style={{ width: '100%', marginRight: 16 }}>
        <Slider
          style={{ width: 'auto', marginLeft: 16 }}
          min={min}
          max={max}
          step={step}
          marks={mark}
          value={value}
          onAfterChange={(e) => onChange(e as number)}
          className={styles.portfolioSlider}
          // activeDotStyle={{ borderColor: 'var(--color-green)' }}
          onChange={(e) => {
            setValue(e as number)
          }}
          dotStyle={{ width: 20, height: 20, borderColor: 'var(--color-green)' }}
        />
        <div className={styles.minMaxContainer}>
          <p>{min}</p>
          <p>{max}</p>
        </div>
      </div>
      <PortfolioConfirmationForm
        //@ts-ignore
        onConfirm={() => onChange(anotherAsset?.quantity)}
        onCancel={() => dispatch(portfolioConstructorActions.changeAnotherAsset(anotherAsset?.seller_product?.id))}
        isVisible={!!anotherAsset && !anotherAsset.is_change}
      />
    </div>
  )
}

export default SliderPortfolio
