import { watchIssuerProduct, watchProduct } from 'analytics'
import { useEffect, useRef } from 'react'
import { useSelector } from 'store/store'

type watchRefType = {
  isAFK?: boolean
  issuer_product?: number
  reqId?: number
}
const initialWatchProduct = async (body: ProductWatchBody) => {
  return fetch('https://wealthback.cecure.io/analytic/product_watch', {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(body),
  })
    .then((r) => {
      return r.json()
    })
    .catch(() => null)
}

const useWatchIssuerProduct = (id?: number, category?: { id: number; name: string }[]) => {
  const userId = useSelector((store) => store.profile.id)
  const isAFK = useSelector((store) => store.profile.isAFK)
  const watchRef = useRef<watchRefType | null>(null)
  useEffect(() => {
    let oldTime = new Date().getTime()
    const i = setInterval(() => {
      const duration = new Date().getTime() - oldTime
      if (watchRef.current) {
        const { isAFK, reqId } = watchRef.current
        if (isAFK) oldTime += 5000
        else {
          if (reqId) watchProduct({ duration, id: reqId })
        }
      }
    }, 5000)
    return () => {
      clearInterval(i)
      const duration = new Date().getTime() - oldTime
      if (watchRef.current?.issuer_product) {
        watchIssuerProduct({ duration, issuer_product: watchRef.current.issuer_product })
      }
      if (watchRef.current?.reqId) watchProduct({ duration, id: watchRef.current.reqId })
    }
  }, [])
  useEffect(() => {
    watchRef.current = {
      ...watchRef.current,
      isAFK: isAFK,
    }
  }, [isAFK])
  useEffect(() => {
    if (id && category && userId) {
      const req = async () => {
        const respId = await initialWatchProduct({
          issuer_product_id: id,
          product_category_id: category[0].id,
          user: userId,
        })
        watchRef.current = {
          ...watchRef.current,
          reqId: respId.id,
          issuer_product: id,
        }
      }
      req()
    }
  }, [id, category])
}
export default useWatchIssuerProduct
