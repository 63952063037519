import { useIdleTimer } from 'react-idle-timer'
import { profileActions } from 'store/profileReducer'
import { useDispatch } from 'store/store'

const IDLE_TIMEOUT = 30000

const useAFK = () => {
  const dispatch = useDispatch()

  const onIdle = () => dispatch(profileActions.setAFK(true))
  const onActive = () => dispatch(profileActions.setAFK(false))

  useIdleTimer({ timeout: IDLE_TIMEOUT, onActive, onIdle })
}

export default useAFK
