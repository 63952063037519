import React, { useEffect } from 'react'
import api from 'api'
import ProductCard from 'components/ProductCard/ProductCard'
import styles from './Products.module.scss'
import Loading from '../Loading/Loading'
import CategoriesFilter from 'components/CategoriesFilter/CategoriesFilter'
import { useParams } from 'react-router'

const Products = () => {
  const { id: categoryId } = useParams()
  const { data: categories } = api.useGetCategoriesQuery()
  const { data: products, isFetching: isProductsLoading } = api.useGetProductsQuery({
    category: Number(categoryId) || undefined,
  })
  const [getLikes, likes] = api.useLazyGetLikesQuery()

  useEffect(() => {
    if (products) getLikes({ products: products.map((p) => p.issuer_firm_product_id ?? p.id) })
  }, [products])

  return (
    <div className={styles.container}>
      {categories !== undefined && <CategoriesFilter categoryId={Number(categoryId)} />}
      {isProductsLoading ? (
        <Loading />
      ) : (
        <>
          {categoryId !== undefined && (
            <h2 style={{ marginBottom: 16 }}>{categories!.find((c) => String(c.id) === categoryId)?.name}</h2>
          )}
          {products === undefined || products.length < 1 ? (
            <div className={styles.noDataMessage}>
              <h2 style={{ textAlign: 'center' }}>No products</h2>
            </div>
          ) : (
            <div className={styles.productsContainer}>
              {products?.map((e) => {
                const liked = !!likes.data?.find((l) => l === (e.issuer_firm_product_id ?? e.id))
                return <ProductCard key={e.id} data={e} liked={liked} />
              })}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Products
