import { profileActions } from 'store/profileReducer'
import { store } from 'store/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'api'
import { getSumQuantity } from 'utils'

type StateType = {
  curPortfolio?: Portfolio
  equity?: number
  assets?: AssetPortfolio[]
  categories?: InvestingCategory[]
  riskLevel?: number
  dailyDrawdown?: number
  sharpe?: number
  remotePortfolioConfiguration?: RemotePortfolioConfigurationType
  myChart?: PortfolioChart
  anotherChart?: PortfolioChart
  anotherAssets?: AnotherAssetPortfolio[]
}

const initialState: StateType = {
  anotherAssets: [
    {
      quantity: 850000,
      seller_product: {
        id: 242,
        max_investment: 1000000,
        min_investment: 50000,
        name: 'Vanguard S&P 500',
        price: 15000,
        price_name: 'Min. Investment',
        step: 10000,
      },
    },
    {
      quantity: 10,
      seller_product: {
        id: 243,
        max_investment: null,
        min_investment: null,
        name: 'SPDR S&P 500',
        price: 15000,
        //@ts-ignore
        price_name: 'Price',
        step: null,
      },
    },
  ],
}

const portfolioConstructorSlice = createSlice({
  name: 'portfolioConstructor',
  initialState,
  reducers: {
    setCurPortfolio: (state, action: PayloadAction<Portfolio>) => {
      // const equity = action.payload.assets.reduce((e: number, a) => {
      //   if (a.seller_product.price_name === 'Min. Investment') return e + a.quantity
      //   else return e + a.quantity * a.seller_product.price
      // }, 0)
      state.curPortfolio = action.payload
      state.equity = action.payload.equity
    },
    setAssets: (state, action: PayloadAction<AssetPortfolio[]>) => {
      state.assets = action.payload
    },
    setAsset: (state, action: PayloadAction<AssetPortfolio>) => {
      if (state.assets)
        state.assets = state?.assets.map((a, i) => {
          if (a.seller_product.id === action.payload.seller_product.id) return action.payload
          else return a
        })
      // if (state.assets) state.assets = [...state.assets, action.payload]
    },
    delAsset: (state, action: PayloadAction<number>) => {
      state.assets = state?.assets?.filter((f) => f.seller_product.id !== action.payload)
    },
    addAsset: (state, action: PayloadAction<number[]>) => {
      //@ts-ignore
      state.assets = action.payload.map((aId) => {
        state?.categories?.map((c) => {
          return c.products.find((f) => f.id === aId)
          // const newAsset = c.products.find(f=> f.id === aId)
          // if(state.assets.find(f=>f.id === newAsset.id)) return state.assets.find(f=>f.id === newAsset.id)
          // else
        })
        return aId
      })
    },
    setCategories: (state, action: PayloadAction<InvestingCategory[]>) => {
      state.categories = action.payload
    },
    setRiskLevel: (state, action: PayloadAction<number>) => {
      state.riskLevel = action.payload
    },
    setDailyDrawdown: (state, action: PayloadAction<number>) => {
      state.dailyDrawdown = action.payload
    },
    setSharpe: (state, action: PayloadAction<number>) => {
      state.sharpe = action.payload
    },
    setEquity: (state, action: PayloadAction<number>) => {
      state.equity = action.payload
    },
    setMyChart: (state, action: PayloadAction<PortfolioChart>) => {
      state.myChart = action.payload
    },
    setAnotherChart: (state, action: PayloadAction<PortfolioChart>) => {
      state.anotherChart = action.payload
    },
    setAnotherAssets: (state, action: PayloadAction<AssetPortfolio[]>) => {
      state.anotherAssets = [
        {
          id: 112,
          quantity: 10,
          seller_product: {
            id: 243,
            max_investment: 0,
            min_investment: 0,
            name: 'SPDR S&P 500',
            price: 15000,
            //@ts-ignore
            price_name: 'Price',
            step: 0,
          },
        },
      ]
    },
    changeAnotherAsset: (state, action: PayloadAction<number | undefined>) => {
      if (action.payload) {
        //@ts-ignore
        state.anotherAssets = state?.anotherAssets.map((a) => {
          if (a.seller_product.id === action.payload) return { ...a, is_change: true }
          else return a
        })
      }
    },
  },
})

export const getNewChart = async (type?: 'my_chart') => {
  const dispatch = store.dispatch

  const assets = store.getState().portfolioConstructor.assets
  //@ts-ignore
  const result = store.dispatch(
    //@ts-ignore
    api.endpoints.getNewChart.initiate({
      equity: getSumQuantity(assets!),
      assets: assets?.map((a) => {
        return { id: a.seller_product.id, quantity: a.quantity, price: a.seller_product.price }
      }),
    }),
  )
  //@ts-ignore
  result.then((res: { data: PortfolioChart }) => {
    if (type === 'my_chart') {
      dispatch(portfolioConstructorActions.setMyChart(res.data))
    } else dispatch(portfolioConstructorActions.setAnotherChart(res.data))
  })
}

// const result = api.endpoints.getNewChart.select()(state)
// const resp = await api.endpoints.
// if (resp.data) {
//   //@ts-ignore
//   dispatch(goalsActions.setGoal(resp.data))
// }
// return resp

export const portfolioConstructorActions = portfolioConstructorSlice.actions
export const portfolioConstructorReducer = portfolioConstructorSlice.reducer
