import React, { useState } from 'react'
import styles from '../Portfolio.module.scss'
import classNames from 'classnames'
import { number2currency } from 'utils'
import Button from 'components/Button/Button'
import MainChart from './MainChart'
import { useNavigate } from 'react-router-dom'
import InvestingChartContainer from '../Chart/InvestingChartContainer'
import SharePortfolioModal from './SharePortfolioModal'
import api from 'api'
import Notifications from 'components/Notifications/Notifications'

type Props = {
  balance: number
  initialAmmount: number
  isEdit?: boolean
}

const MainBlock = ({ balance, initialAmmount, isEdit }: Props) => {
  const navigate = useNavigate()
  const [isVisibleShareModal, setIsVisibleShareModal] = useState<boolean>(false)
  const dif = balance - initialAmmount
  const { data: editDemond } = api.useGetDemandQuery({ edit: 1, is_archived: 0 })

  return (
    <div className={classNames([styles.block, styles.mainChartBlock])}>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <div>
          <p style={{ fontSize: 20 }}>
            Balance: <b>{number2currency(balance)}</b>
          </p>
          <p style={{ fontSize: 14, color: dif < 0 ? 'var(--color-red)' : 'var(--color-green)' }}>
            {(dif < 0 ? '- ' : '+ ') + number2currency(Math.abs(dif))} (
            {Math.abs((dif / initialAmmount) * 100).toFixed(2)}%)
          </p>
        </div>
        {editDemond && editDemond.length > 0 && (
          <Notifications.Warning
            title={`You already have ${editDemond.length + editDemond.length === 1 ? 'application' : 'applications'} `}
            style={{ marginLeft: 16 }}
          />
        )}
        {!isEdit ? (
          <>
            <Button style={{ marginLeft: 'auto' }}>Request update</Button>
            <Button style={{ marginLeft: 16 }} onClick={() => navigate('/portfolio_constructor')}>
              Edit
            </Button>
            <Button style={{ marginLeft: 16 }} onClick={() => setIsVisibleShareModal(true)}>
              Share
            </Button>
          </>
        ) : null}
      </div>
      {/* <MainChart /> */}
      <InvestingChartContainer isEdit={isEdit} />
      {isVisibleShareModal && <SharePortfolioModal onClose={setIsVisibleShareModal} link={window.location.origin} />}
    </div>
  )
}

export default MainBlock
