import { watchCategory } from 'analytics'
import { useEffect, useRef } from 'react'
import { useSelector } from 'store/store'

type watchRefType = {
  isAFK?: boolean
  category?: number
}
const useWatchCategory = (id?: number) => {
  const isAFK = useSelector((store) => store.profile.isAFK)
  const watchRef = useRef<watchRefType | null>(null)
  useEffect(() => {
    let oldTime = new Date().getTime()
    const i = setInterval(() => {
      if (watchRef.current?.isAFK) oldTime += 5000
    }, 5000)
    return () => {
      clearInterval(i)
      const duration = new Date().getTime() - oldTime
      if (watchRef.current?.category) {
        watchCategory({ duration, category: watchRef.current.category })
      }
    }
  }, [id])
  useEffect(() => {
    watchRef.current = {
      ...watchRef.current,
      isAFK: isAFK,
    }
  }, [isAFK])
  useEffect(() => {
    if (id) {
      watchRef.current = {
        ...watchRef.current,
        category: id,
      }
    }
  }, [id])
}
export default useWatchCategory
