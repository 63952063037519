import React, { useState } from 'react'
import styles from './SelectParams.module.scss'
import classNames from 'classnames'

type PropsType = {
  onChange: (value: any) => void
  data: SelectType
}
const SelectParams: React.FC<PropsType> = ({ data, ...props }) => {
  const [value, setValue] = useState<string>()
  const [showOptions, setShowOptions] = useState(false)

  const onChange = (value: string) => {
    setValue(value)
    props.onChange(value)
  }

  return (
    <div className={styles.container} onClick={() => setShowOptions((v) => !v)}>
      <span className={styles.title}>{data.name}:</span>
      <div className={styles.select}>
        <p className={classNames([styles.currentValue, { [styles.placeholder]: value === undefined }])}>
          {value ?? 'Select...'}
        </p>
        <div className={classNames(styles.options, { [styles.optionsOpen]: showOptions })}>
          {data.options.map((e) => (
            <p key={e} className={styles.option} onClick={() => onChange(e)}>
              {e}
            </p>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SelectParams
