import api from 'api'
import React from 'react'
import CreatePortfolio from './CreatePortfolio/CreatePortfolio'
import Portfolio from './Portfolio'
import Loading from '../Loading/Loading'
import { useSelector } from 'store/store'
import PortfolioStatus from './Components/PortfolioStatus'

const InvestingContainer = () => {
  const isHasPortfolio = true
  const client_id = useSelector((store) => store.profile.client_id)
  const demand = api.useGetDemandQuery({ client: client_id!, create: 1, is_archived: 0 })
  const portfolio = api.useGetPortfolioQuery({ client: client_id! })
  //@ts-ignore
  if (portfolio?.error?.status === 404 && demand.data?.length === 0) return <CreatePortfolio />
  //@ts-ignore
  if (portfolio?.error?.status === 404 && demand.data?.length > 0) return <PortfolioStatus />
  if (portfolio?.error) return <h1>Error. Please come back later</h1>
  if (!portfolio?.data) return <Loading />
  return <Portfolio />
  //@ts-ignore
  // if (demand?.data?.status!) return <PortfolioStatus status={demand?.data?.status?.name_for_user} />
  // else return <CreatePortfolio />
  // else return <CreatePortfolio />
}

export default InvestingContainer
