import api from 'api'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './Product.module.scss'
import { number2currency } from 'utils'
import Loading from '../Loading/Loading'
import Params from 'pages/Product/Params/Params'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import Page404 from '../Page404/Page404'
import ProductChart from 'components/Charts/ProductChart'
import classNames from 'classnames'
import { useSelector } from '../../store/store'
import { watchIssuerProduct } from 'analytics'
import noImage from 'assets/images/image_not_available.png'
import LazyLoading from '../../components/LazyLoading/LazyLoading'
import translation from 'assets/locales/en/translation.json'
import VideoPlayer from 'components/VideoPlayer/VideoPlayer'
import Gallery from '../../components/Gallery/Gallery'
import Modal from '../../components/Modal/Modal'
import Button from '../../components/Button/Button'
import useWatchIssuerProduct from 'hooks/useWatchIssuerProduct'
import SVG from 'components/SVG'
dayjs.extend(LocalizedFormat)

type Props = {
  product: Product
  sellerProducts: ProductSeller[]
  subscribes: ShortSubscribe[]
  liked: boolean
}

const Product = ({ product, sellerProducts, subscribes, liked }: Props) => {
  const [showThanksModal, setShowThanksModal] = useState(false)
  const [parametersFilled, setParametersFilled] = useState(false)
  const params = useRef<(SelectType | InputType | SliderType)[]>(
    product.params?.map((p) => ({ ...p, value: undefined })) ?? [],
  )
  const isHasParams = product.params && product.params.length > 0
  const [subscribeRequest, subscribeResponse] = api.useSetSubscribeMutation()
  const [deleteSubscribeRequest, { isLoading: delSubscribeLoading }] = api.useDeleteSubscribeMutation()
  const [like] = api.usePostLikeMutation()
  const [deleteLike] = api.useDeleteLikeMutation()
  const isExpired = product.expire_date ? product.expire_date * 1000 < Date.now() : false

  useWatchIssuerProduct(product.id, product.categories)

  const galleryItems: React.ReactNode[] = product.images
    .map((i) => <LazyLoading key={i.id} src={i.url} style={{ background: '#' + i.background_color }} />)
    .concat(
      product.videos.map((v) => <VideoPlayer key={v.id} url={v.url} videoId={v.id} poster={v.url_preview} controls />),
    )

  const onClickLike = (e: React.MouseEvent) => {
    e.stopPropagation()
    const id = product.issuer_firm_product_id ?? product.id
    if (liked) deleteLike(id)
    else like(id)
  }

  const onSubscribe = async (sellerProductId: number) => {
    //@ts-ignore
    subscribeRequest({ product: sellerProductId, params: params.current }).then(() => setShowThanksModal(true))
  }

  const onDeleteSubscribe = (id: number) => {
    deleteSubscribeRequest(id)
  }

  return (
    <div className={styles.page}>
      {showThanksModal && (
        <Modal>
          <div className={styles.thanksModal}>
            <h3 style={{ marginBottom: 16 }}>Thanks for subscribe</h3>
            <p style={{ marginBottom: 24 }}>Our adviser will call you soon</p>
            <Button style={{ width: 100 }} onClick={() => setShowThanksModal(false)}>
              Ok
            </Button>
          </div>
        </Modal>
      )}
      <div className={styles.galleryWithInfo}>
        <div className={styles.gallery}>
          {galleryItems.length > 0 ? (
            <Gallery bullets controls className={styles.gallery} showFullscreenBtn>
              {galleryItems}
            </Gallery>
          ) : (
            <img
              src={noImage}
              alt={translation.Product.img_alt}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          )}
        </div>
        <div className={styles.subscribeInfoBlock}>
          <div style={{ display: 'flex' }}>
            <h1 className={styles.name}>
              {product.name} {isExpired ? '(expired)' : ''}
            </h1>
            <div className={classNames([styles.likeBlock, { [styles.liked]: liked }])} onClick={onClickLike}>
              <SVG.Like />
              <span>{product.like_count}</span>
            </div>
          </div>
          {product.price > 0 && (
            <h3 className={styles.price}>
              {product.price_name}: {number2currency(product.price)}
            </h3>
          )}
          {product.expire_date && (
            <h3 className={styles.price}>Expire date: {dayjs(product.expire_date * 1000).format('LL')}</h3>
          )}
          {isHasParams ? (
            <Params
              params={params}
              onParametersFilledChange={setParametersFilled}
              capacity={product.capacity_available}
            />
          ) : null}
          {sellerProducts.map((pdct) => {
            const subscribe = subscribes.find((s) => s.product === pdct.id)
            const isSubscribed = subscribe !== undefined

            return (
              <div key={pdct.id} className={styles.sellerProductBlock}>
                <figure className={styles.sellerInfo}>
                  <img src={pdct.seller_firm.avatar_url ?? undefined} alt={''} className={styles.sellerAvatar} />
                  <figcaption>{pdct.seller_firm.name}</figcaption>
                </figure>
                <p className={styles.subscribeStatus}>{subscribe?.status ? 'Status: ' + subscribe?.status : ''}</p>
                <Button
                  disabled={
                    (isHasParams && !parametersFilled && !isSubscribed) ||
                    subscribeResponse.isLoading ||
                    delSubscribeLoading ||
                    isExpired
                  }
                  onClick={() => (isSubscribed ? onDeleteSubscribe(subscribe!.id) : onSubscribe(pdct.id))}
                  className={classNames([styles.subscribeBtn, { [styles.unsubscribeBtn]: isSubscribed }])}>
                  {isSubscribed ? translation.Product.unsubscribe : translation.Product.subscribe}
                </Button>
              </div>
            )
          })}
        </div>
      </div>
      <div className={styles.bigInfo}>
        {product.description && (
          <div className={styles.bigInfoBlock}>
            <h3 style={{ marginBottom: 16 }}>{translation.Product.description}</h3>
            <p className={styles.description}>{product.description}</p>
          </div>
        )}
        {product.attachments.length > 0 && (
          <div className={styles.bigInfoBlock}>
            <h3 style={{ marginBottom: 12 }}>{translation.Product.attachments}</h3>
            {product.attachments.map((a, i) => (
              <a key={i} target={'_blank'} href={a.url} rel="noreferrer" style={{ display: 'block', marginBottom: 10 }}>
                {a.name}
              </a>
            ))}
          </div>
        )}
        {product.charts.length > 0 && (
          <div className={styles.bigInfoBlock}>
            <h3 style={{ marginBottom: 12 }}>{translation.Product.charts}</h3>
            {product.charts.map((chart, i) => (
              <ProductChart data={chart} key={i} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const ProductContainer = () => {
  const { id: productId } = useParams()
  const id = Number(productId)

  if (isNaN(id)) return <Page404 />

  const userId = useSelector((state) => state.profile.id!)
  const { data: subscribes, isLoading: subscribesLoading } = api.useGetShortSubscribesQuery({ user: userId })
  const { data: sellerProducts, isLoading: sellerProductsLoading } = api.useGetProductSellersQuery(id)
  const { data: product, isLoading: productLoading, isError } = api.useGetProductQuery(id)
  const { data: likes, isLoading: likesLoading } = api.useGetLikesQuery({ products: [id] })

  if (isError) return <Page404 />
  else if (productLoading || subscribesLoading || sellerProductsLoading || likesLoading) return <Loading />
  else {
    return (
      <Product
        product={product!}
        sellerProducts={sellerProducts!}
        subscribes={subscribes!}
        liked={!!likes?.find((l) => l === id)}
      />
    )
  }
}

export default ProductContainer
