import React from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { number2currency } from 'utils'
import { store, useSelector } from 'store/store'
const AssetsChart = React.memo(function FundsChart({ data }: { data?: { name: string; y: number; color: string }[] }) {
  const options: Highcharts.Options = {
    chart: {
      type: 'pie',
      width: 300,
      height: 300,
      backgroundColor: 'transparent',
    },
    title: {
      text: '<b>Assets</b>',
      align: 'center',
      verticalAlign: 'middle',
    },

    plotOptions: {
      pie: {
        // enableMouseTracking: false,
        // dataLabels: { enabled: false }
        // showInLegend: true,
        allowPointSelect: false,
        cursor: 'pointer',
        size: 200,
        tooltip: {
          pointFormat: '{point.percentage:.0f}%',
        },
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Assets',
        type: 'pie',
        innerSize: '70%',
        // data: [
        //   { name: 'AAPL', y: 4, sliced: true },
        //   { name: 'BABA', y: 3 },
        //   { name: 'NFLX', y: 3 },
        // ],
        data,
      },
    ],
    // yAxis: {
    //   labels: {
    //     style: {
    //       textOverflow: 'none',
    //     },
    //   },
    // },
  }

  return <HighchartsReact highcharts={Highcharts} options={options} constructorType={'chart'} />
})

export default AssetsChart
