import React, { useEffect } from 'react'
import styles from '../../Portfolio.module.scss'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import classNames from 'classnames'
import { number2currency } from 'utils'
import { store, useSelector } from 'store/store'
import AssetsBlockLine from './AssetsBlockLine'
import Button from 'components/Button/Button'
import api from 'api'
import { useNavigate } from 'react-router'
import AssetsChart from './AssetsChart'

const colors = [
  '#2f7ed8',
  '#0d233a',
  '#8bbc21',
  '#910000',
  '#1aadce',
  '#492970',
  '#f28f43',
  '#77a1e5',
  '#c42525',
  '#a6c96a',
]

type AssetsType = {
  assets?: AssetPortfolio[]
  isEdit?: boolean
  equity: number
}

const AssetsBlock: React.FC<AssetsType> = ({ assets, isEdit, equity }) => {
  const getIsPercent = (quantity: number, priceName: string, price: number) => {
    if (priceName === 'Min. Investment') return ` (${Number(((quantity / equity) * 100).toFixed(2))}%)`
    else return ` (${Number(((quantity / equity) * 100).toFixed(2))}%)`
  }
  const navigate = useNavigate()

  const [sendAssets, resultAssets] = api.usePostDemandMutation()
  const client_id = useSelector((store) => store.profile.client_id)
  useEffect(() => {
    if (resultAssets.isSuccess) navigate('/investing')
  }, [resultAssets])
  return (
    <div className={classNames([styles.block, styles.assetsBlock])}>
      <div style={{ minWidth: 300, display: 'flex', width: 300, alignSelf: 'center' }}>
        <AssetsChart
          data={assets?.map((a, i) => {
            return {
              name: a.seller_product.name,
              y: a.seller_product.price_name === 'Min. Investment' ? a.quantity : a.quantity * a.seller_product.price,
              color: colors[i],
            }
          })}
        />
      </div>
      <table className={styles.fundsTable}>
        <colgroup>
          <col style={{ width: '10px', paddingRight: '10px' }} />
          <col />
          {isEdit && <col style={{ width: '100%' }} />}
          <col style={{ width: '120px' }} />
        </colgroup>
        <tbody>
          {assets?.map((a, i) => (
            <AssetsBlockLine
              key={a?.seller_product.id}
              color={colors[i]}
              asset={a}
              percent={getIsPercent(a.quantity, a.seller_product.price_name, a.seller_product.price)}
              isEdit={isEdit}
            />
          ))}
        </tbody>
      </table>
      {isEdit && (
        <Button
          style={{ width: 210, marginLeft: 'auto', marginTop: 20 }}
          onClick={() =>
            sendAssets({
              type: 'change',
              client: client_id!,
              //@ts-ignore
              assets: assets?.map((a) => {
                return { id: a?.seller_product.id, quantity: a?.quantity }
              }),
            })
          }>
          Submit a change request
        </Button>
      )}
    </div>
  )
}

export default AssetsBlock
