import SVG from 'components/SVG'
import React from 'react'
import styles from './PortfolioConfirmationForm.module.scss'

type PropsType = {
  onConfirm: () => void
  onCancel: () => void
  isVisible?: boolean
}

const PortfolioConfirmationForm: React.FC<PropsType> = ({ onConfirm, onCancel, isVisible }) => {
  if (isVisible)
    return (
      <div className={styles.container}>
        <button className={styles.agreement} onClick={() => onConfirm()}>
          <SVG.СheckMark style={{ height: '10px', strokeWidth: 4 }} />
        </button>
        <button className={styles.refusal} onClick={() => onCancel()}>
          <SVG.Cross style={{ height: 10, strokeWidth: 4 }} />
        </button>
      </div>
    )
  else return <div style={{ minWidth: 46, height: 20 }}></div>
}

export default PortfolioConfirmationForm
