import Footer from 'components/Footer/Footer'
import React from 'react'
import { Outlet } from 'react-router'
import Header from '../Header/Header'
import styles from './Layout.module.scss'

const Layout = ({ children }: { children: JSX.Element }) => {
  return (
    <>
      <Header />
      <div className={styles.content}>{children}</div>
      <Footer />
    </>
  )
}

export default Layout
